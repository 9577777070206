import React from "react";

interface Props {
  small?: boolean;
  center?: boolean;
}

export const Logo: React.FC<Props> = ({ small, center = true }) => {
  return (
    <div
      className={`${small ? "w-16 h-16" : "w-20 h-20"} ${
        small ? "mb-4" : "mb-8"
      } ${center ? "mx-auto" : ""} mt-4 bg-grass rounded-full `}
    />
  );
};

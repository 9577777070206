import Link from "next/link";
import React from "react";
export const Layout: React.FC = ({ children }) => {
  return (
    <>
      <div className="bg-white fixed top-0 left-0 bottom-0 right-0 flex flex-col justify-between text-sm">
        <div className=" p-4 flex-auto overflow-y-scroll">{children}</div>
        <div className="text-xs p-4 flex flex-row justify-between items-center">
          <Link href="/about">Vad är detta?</Link>
          <Link href="/terms">Villkor</Link>
          <Link href="/policy">Integritetspolicy</Link>
        </div>
      </div>
    </>
  );
};

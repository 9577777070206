import tw from "tailwind-styled-components";

export const Button = tw.button`
uppercase
bg-grass
  text-xs
  py-2
  px-3
  text-white
  font-semibold
  rounded
  shadow
  hover:bg-yellow-700-700
  hover:text-white
  focus:outline-none
  focus:ring-2
  focus:ring-indigo-400
  focus:ring-opacity-75
  whitespace-nowrap
`;
